.branding-title {
	margin: 0;
	@include font-size(46);
	font-weight: normal;
	line-height: 1em;
}

.logo {
	display: block;
	margin: 0 auto;
	line-height: 0;
	text-align: center;
	text-decoration: none;
	color: theme(primary, base) !important;
	white-space: nowrap;
	strong {
		display: block;
		@include font-size(36);
		font-family: $font-family-headings;
		line-height: 1em;
		word-spacing: -0.2em;
	}
	small {
		display: inline-block;
		padding: 5px 20px 3px;
		@include font-size(17);
		font-family: $font-family-body;
		font-weight: $body-weight-bold;
		line-height: 1em;
		text-transform: uppercase;
		letter-spacing: 8px;
		border-radius: 100px;
		background-color: theme(secondary, base);
		color: $white;
	}
}

	.branding-logo {}

	.site-info-logo {}